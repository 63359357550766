import config from "../config/config";
let proxy = config.proxy;
let apiKey = config.apiKey

/**
 * For each field that is stored as an array/list, the data we pass must make it explicity clear to microsoft graph that is the data type being passed
 */
function formDataToListItem(config) {
  const body = {
    fields: {
      ...config,
    },
  };

  for (const [key, value] of Object.entries(config)) {
    if (Array.isArray(value)) {
      body.fields[key + "@odata.type"] = "Collection(Edm.String)";
    }
    body.fields[key] = value;
  }

  return body;
}

export async function AddEventConfig(configData, key) {
  configData = { ...configData, EventKey: key, Title: configData.EventName };
  const SharepointListData = formDataToListItem(configData);

  const registration = await fetch(`${proxy}/event`, {
    method: "POST",
    headers: { "Content-Type": "application/json",
               "x-api-key": `${apiKey}`},
    body: JSON.stringify(SharepointListData),
  })
    .then((response) => {
      if (response.status === 201) {
        return response.json();
      } else {
        throw new Error(
          "There was an error adding event config through lambda: ",
          response.status
        );
      }
    })
    .then((data) => {
      return data;
    });

  console.log("Registration: ", registration);
  return registration;
}

export async function updateEventConfig(data, id) {
  let copy = Object.assign({}, data);
  let updateData = formDataToListItem(copy).fields;

  const updatedConfig = await fetch(`${proxy}/event/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json",
               "x-api-key": `${apiKey}`},
    body: JSON.stringify(updateData),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(
          "There was an issue updating student registration in list through lambda: ",
          response.status
        );
      }
    })
    .then((data) => {
      return data;
    });

  return updatedConfig;
}

export async function getDefaultEventConfig() {
  const defaultConfig = await fetch(`${proxy}/defaultevent/`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Error getting event: ", response.statusCode);
      }
    })
    .then((data) => {
      //Return event data from the first event that matches our search for the default event
      return data.value[0].fields;
    })
    .catch((error) => {
      console.log(
        "Error while getting event config in response from gateway or lambda: ",
        error
      );
    });

  return defaultConfig;
}

export async function getEventConfigByKey(key) {
  console.log("Request: [" + key + "]");

  const eventConfig = await fetch(`${proxy}/eventkey/${key}`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Error getting event: ", response.statusCode);
      }
    })
    .then((data) => {
      //Return event data from the first matching event from our filter by event key
      return data.value[0].fields;
    })
    .catch((error) => {
      console.log(
        "Error while getting event config in response from gateway or lambda: ",
        error
      );
    });

  return eventConfig;
}

export async function getAllEventConfigs() {
  const allConfigs = await fetch(`${proxy}/events`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Error getting event configs: ", response.statusCode);
      }
    })
    .then((lambdaResponse) => {
      return lambdaResponse.value;
    })
    .catch((error) => {
      console.log(
        "Error while getting all event configs in response from gateway or lambda: ",
        error
      );
    });

  return allConfigs;
}

export async function getSharepointMembers() {
  const members = await fetch(`${proxy}/admins`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Error getting event configs: ", response.statusCode);
      }
    })
    /*.then((data) => {
        let membersList = data.value;
        return membersList.map((member) => {
          return member.mail.toLowerCase();
        });
    })*/
    .catch((error) => {
      console.log(
        "Error while getting sharepoint members in response from gateway or lambda: ",
        error
      );
    });

  return members;
}

export async function getRecruitmentLeads() {
  try {
    const defaultConfig = await getDefaultEventConfig();
    let recruitmentLeads = defaultConfig.RecruitmentLeads.map((lead) => {
      return lead.toLowerCase();
    });

    return recruitmentLeads;
  } catch (error) {
    console.log("Error getting recruitment leads: ", error);
  }
}
