import config from "../config/config";
let proxy = config.proxy;
let apiKey = config.apiKey
const FormData = require("form-data");

export async function uploadResume(file, fileName) {
  let webUrl = null;

  const data = new FormData();
  data.append("name", fileName);
  data.append("files", file);

  webUrl = await fetch(`${proxy}/resume`, {
    method: "POST",
    body: data,
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 201) {
        return response.json();
      } else {
        throw new Error(
          "There was an issue uploading the resume through lambda: ",
          response
        );
      }
    })
    .then((data) => {
      if (data.webUrl) {
        return data.webUrl;
      } else {
        throw new Error(
          "There was an issue uploading the resume through lambda: "
        );
      }
    });

  return webUrl;
}

function formDataToListItem(student) {
  const body = {
    fields: {
      ...student,
    },
  };

  for (const [key, value] of Object.entries(student)) {
    if (Array.isArray(value)) {
      body.fields[key + "@odata.type"] = "Collection(Edm.String)";
    }
    body.fields[key] = value;
  }

  return body;
}

function createHistoryRecord(name) {
  const now = new Date();
  const formattedDate = now.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const dateTimeString = `${formattedDate} / ${formattedTime}`;
  let historyRecord = name + " / " + dateTimeString;
  return historyRecord;
}

export async function AppendSharepointList(formData, resumeLink, key) {
  console.log("Resume link provided to append sharepoint list: ", resumeLink);
  const creationRecord = createHistoryRecord(formData.Name);
  const history = [];
  history.push(creationRecord);

  const now = new Date();
  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const eventKey = key.toLowerCase();

  const student = {
    ...formData,
    SubmissionTime: formattedTime,
    EventKey: eventKey,
    Resume: resumeLink,
    NotesHistory: history,
  };

  const SharepointListData = formDataToListItem(student);

  const registration = await fetch(`${proxy}/student`, {
    method: "POST",
    headers: { "Content-Type": "application/json",
               "x-api-key": `${apiKey}`},
    body: JSON.stringify(SharepointListData),
  }).then((response) => {
    if (response.status === 201) {
      return response.json();
    } else {
      throw new Error(
        "There was an error uploading student registration through lambda: ",
        response.status
      );
    }
  });

  return registration;
}

export async function updateSharepointRecord(data, id, recruiter) {
  let copyData = Object.assign({}, data);
  let history = Object.assign([], copyData.NotesHistory);

  const creationRecord = createHistoryRecord(recruiter);
  history.push(creationRecord);
  let updateData = { ...copyData, NotesHistory: history };
  let graphData = formDataToListItem(updateData).fields;

  const updatedStudent = await fetch(`${proxy}/student/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json",
               "x-api-key": `${apiKey}`},
    body: JSON.stringify(graphData),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(
        "There was an issue updating student registration in list through lambda: ",
        response.status
      );
    }
  });

  return updatedStudent;
}

export async function getStudents() {
  const students = await fetch(`${proxy}/students`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Error getting all students: ", response.statusCode);
      }
    })
    .then((data) => {
      return data.value;
    });

  return students;
}

export async function getStudentsByEventKey(key) {
  const eventKey = key.toLowerCase();

  if (!key) {
    throw new Error(`No Event Key provided to get students`);
  }

  const students = await fetch(`${proxy}/students/${eventKey}`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(
          `Error getting students by event key [${eventKey}] + : `,
          response.statusCode
        );
      }
    })
    .then((data) => {
      return data.value;
    })
    .catch((error) => {
      console.log(
        "Error while getting student in response from gateway or lambda: ",
        error
      );
    });

  return students;
}

export async function getStudent(id) {
  const student = await fetch(`${proxy}/student/${id}`, {
    headers: { "x-api-key": `${apiKey}`},
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Error getting student: ", response.statusCode);
      }
    })
    .then((data) => {
      return data.fields;
    });

  return student;
}

export async function getStudentRecords(event) {
  getStudentsByEventKey(event.eventConfig.EventKey).then((records) => {
    if (records) {
      const fileData = records.map((student) => {
        console.log("Testing fields", student.fields);
        let studentInfo = student.fields;
        var exportData = [];

        var tags = "None";
        if (studentInfo.Tags) {
          tags = studentInfo.Tags.join("/");
        }
        exportData.push(
          studentInfo.Name +
            "," +
            studentInfo.Email +
            "," +
            studentInfo.PhoneNumber +
            "," +
            studentInfo.GradYear +
            "," +
            studentInfo.GradMonth +
            "," +
            studentInfo.GPA +
            "," +
            studentInfo.PreviouslyJJ
        );
        exportData.push('"' + studentInfo.Major.join("/") + '"');
        exportData.push(
          studentInfo.RoleInterest +
            "," +
            studentInfo.CapabilityInterest.join("/")
        );
        if (studentInfo.Notes) {
          exportData.push('"' + studentInfo.Notes.replace(/\n/g, "/") + '"');
        } else {
          exportData.push("None");
        }
        var interviewers = []
        exportData.push(
          tags +
            "," +
            studentInfo.Rating +
            "," +
            studentInfo.Sponsorship +
            "," +
            studentInfo.Relocation +
            "," +
            studentInfo.Resume +
            "," +
            studentInfo.SubmissionTime +
            "," +
            studentInfo.NotesHistory.slice(1).map((record) => {
              let name = record.split("/")[0].trim()
              var interviewer = "";
              if(name.includes(",") && !(interviewers.includes(name))){
                console.log(name)
                let nameArray = name.split(",")
                interviewer = nameArray[1] + " " + nameArray[0]
                interviewer = interviewer.trim()
                interviewers.push(name)
              }
              return interviewer
            }) +
            "\n"
        );
        return exportData;
      });
      console.log(fileData);
      fileData.unshift(
        ",Full Name, Email, Phone Number, Grad Year, Grad Month, GPA, Previously J&J, Major, Role Interest," +
          "Capability Interest, Notes, Tags, Rating, Sponsorship, Relocation, Resume, Submission Time, Reviewed by\n"
      );
      var textFileAsBlob = new Blob([fileData], { type: "text/csv" });
      var fileNameToSaveAs =
        event.eventConfig.EventName.replace(/\s/g, "-") +
        "-" +
        event.eventConfig.EventDate +
        ".csv";
      var downloadLink = document.createElement("a");
      downloadLink.download = fileNameToSaveAs;
      downloadLink.innerHTML = "Download File";
      if (window.webkitURL != null) {
        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
      } else {
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        downloadLink.onclick = document.body.removeChild(event.target);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
      }

      downloadLink.click();
    } else {
      console.log("There was a problem getting student records");
    }
  });
}
